/* =============================================================
 * import
 * ========================================================== */
import {gsap} from "gsap/gsap-core";
import {CustomEase} from "gsap/CustomEase";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Swiper from 'swiper/bundle';
import {mediaQuery} from "../modules/common";

gsap.registerPlugin(CustomEase, ScrollTrigger);
CustomEase.create("slide", ".32, .95, .6, 1");

/* =============================================================
 * function
 * ========================================================== */
let swiper;

export default function exe(data) {
  const namespace = data.current.namespace; // barbajsからdataを取得

  // 遷移元ページが存在しない場合（TOPページにランディングした場合）のみ実行
  if (namespace === '') {
    const header = document.querySelector('#header');
    if (header != null) {
      gsap.from(header, {
        webkitClipPath: 'inset(0% 0% 100%)',
        clipPath: 'inset(0% 0% 100%)',
        duration: 2,
        delay: 4,
        ease: 'slide',
      });
    }
  }

  productSwiper();

  window.addEventListener('resize', function() {
    productSwiper();
  });

}


function productSwiper() {

  const cursor = document.getElementById("cursor");
  const swipeContent = document.querySelector('.swiper');
  const windowWidth = window.innerWidth;

  /*
  const updateCustomClasses = (swiperInstance) => {
    const slides = swiperInstance.slides;

    slides.forEach((slide) => {
      slide.classList.remove('swiper-slide-prev-first');
      slide.classList.remove('swiper-slide-next-last');
    });

    const activeIndex = swiperInstance.activeIndex;

    if (slides[activeIndex - 2]) {
      slides[activeIndex - 2].classList.add('swiper-slide-prev-first');
    }
    if (slides[activeIndex + 2]) {
      slides[activeIndex + 2].classList.add('swiper-slide-next-last');
    }
  };
*/

  const rotateSlider = (elm) => {
    elm.slides.forEach((slide, index) => {  // eslint-disable-line @typescript-eslint/no-unused-vars
      const diameterCircleY = 2186;
      const diameterCircleX = 300;

      const rotateItem = 10; // 円を形作るアイテムの数（角度が変わります）
      const rotateDeg = 360 / rotateItem;

      /*
      if (mediaQuery(windowWidth) !== 'sp') {
        diameterCircleY = 2186; // 形作る円の直径（縦幅の計算用）
        diameterCircleX = 300; // 形作る円の直径（横幅の計算用）_ 差を付けることで変化幅が大きく見える
      } else {
        diameterCircleY = 1200; // 形作る円の直径（縦幅の計算用）
        diameterCircleX = 300; // 形作る円の直径（横幅の計算用）_ 差を付けることで変化幅が大きく見える
      }
      */

      const radiusY = diameterCircleY / 2; // 形作る円の半径
      const radiusX = diameterCircleX / 2; // 形作る円の半径

      const angle = (slide.progress + 0.05) * -rotateDeg; // 各スライドの回転角度
      const radian = angle * (Math.PI / 180);
      const x = radiusX + radiusX * Math.sin(radian);
      const y = radiusY - radiusY * Math.cos(radian);


      gsap.set(slide, {
        x: () => x - radiusX,
        y: () => y,
        // rotate: () => angle,
      });

      cursor.classList.remove('active_swipe'); // ポインター要素からクラスを削除。

      /*
      // debug用の処理
      const progressElement = slide.querySelector('.progress');
      if (progressElement) {
        progressElement.textContent = slide.progress.toFixed(2); // 小数点以下2桁まで表示
      }
      console.log(index + ": 進捗: " + (slide.progress + 0.05));
      console.log(index + ": angle: " + angle);
      console.log(index + ": radian: " + radian);
      console.log(index + ": x: " + x);
      console.log(index + ": y: " + y);
      const slideImage = slide.querySelector('.top_product_list_item_figure');
      gsap.set(slideImage, {
        rotate: () => angle,
      });
      */

    });
  };

  const initSwiper = () => {
    swiper = new Swiper('.swiper', { // eslint-disable-line @typescript-eslint/no-unused-vars
      slidesPerView: 'auto', // コンテナ内に表示させるスライド数（CSSでサイズ指定する場合は 'auto'）
      speed: 1200, // スライドアニメーションのスピード（ミリ秒）
      initialSlide: 1, // スライドの開始位置
      centeredSlides : true,
      loop: true,  // 無限ループさせる
      loopAdditionalSlides: 2, // 無限ループさせる場合に複製するスライド数
      grabCursor: true, // PCでマウスカーソルを「掴む」マークにする
      watchSlidesProgress: true, // スライドの進行状況を監視する
      preventClicks: true, // true: スワイプ中の不要なクリックを無効化
      preventClicksPropagation: true, // true: スワイプ中の不要なクリックイベントを無効化
      freeMode: {
        enabled: true, // true：自由にドラッグ（スワイプ）できるようにする。
        momentum: false, // 有効にすると、スライドを離した後もしばらくの間、スライドが動き続けます
        momentumRatio: 1, // 数字が大きいほど惰性スクロールの距離が伸びる（freeMode: trueとfreeModeMomentum:trueが前提）
        momentumVelocityRatio: .2, // 数字が大きいほど惰性スクロールのスピードが速くなる（freeMode: trueとfreeModeMomentum:trueが前提）
        sticky: false,  // スライド位置へのスナップの有効無効
      },
      mousewheel: {
        forceToAxis: true,
      },

      on: {
        progress: function () {
          rotateSlider(this);
        },
        /*
        init: function () {
          updateCustomClasses(this);
        },
        slideChange: function () {
          updateCustomClasses(this);
        },
        */
      },
    });

    swipeContent.addEventListener("mouseenter", function() {
      cursor.classList.add('active_swipe');
    });

    swipeContent.addEventListener("mouseleave", function() {
      cursor.classList.remove('active_swipe');
    });
  };

  const destroySwiper = () => {
    if (swiper) {
      swiper.destroy();
      swiper = null;
      cursor.classList.remove('active_swipe');
    }
  };

  const handleSwiper = () => {
    destroySwiper();

    if (mediaQuery(windowWidth) === 'pc' && !swiper) {
      initSwiper();
    }
  };

  handleSwiper();
}



/*
function menuSwitcher() {
  const listeners = [];
  let onObj;
  let offObj;
  const btn = document.querySelectorAll(".js-switcherBtn");
  const onSwitcher = (e) => {
    const target = document.querySelector('[data-modal-area="' + e.currentTarget.dataset.modalBtn + '"]');
    target.classList.add('active');
  };
  const offSwitcher = (e) => {
    const target = document.querySelector('[data-modal-area="' + e.currentTarget.dataset.modalBtn + '"]');
    target.classList.remove('active');
  };

  const init = () => {
    if (mediaQuery() !== 'sp') {
      if (btn.length > 0) {
        btn.forEach(elem => {
          onObj = {
            btn: elem,
            handleEvent: onSwitcher,
          };
          offObj = {
            btn: elem,
            handleEvent: offSwitcher,
          };
          elem.addEventListener('mouseenter', onObj, false);
          elem.addEventListener('mouseleave', offObj, false);
          listeners.push([elem, 'mouseenter', onObj, false]);
          listeners.push([elem, 'mouseleave', offObj, false]);
        });
      }
    }
  };

  init();
  window.addEventListener("resize", init);
  eventObserver.observeEvents(listeners);
}
*/


/*
function productSwiper() {

  const updateCustomClasses = (swiperInstance) => {
    const slides = swiperInstance.slides;

    slides.forEach((slide) => {
      slide.classList.remove('swiper-slide-prev-first');
      slide.classList.remove('swiper-slide-next-last');
    });

    const activeIndex = swiperInstance.activeIndex;

    if (slides[activeIndex - 2]) {
      slides[activeIndex - 2].classList.add('swiper-slide-prev-first');
    }
    if (slides[activeIndex + 2]) {
      slides[activeIndex + 2].classList.add('swiper-slide-next-last');
    }
  };

  /!*
  const rotateSlider = (slider, progress) => {
    const numSlides = slider.slides.length;
    // const radius = 779.23;
    const radius = 500;
    const anglePerSlide = (2 * Math.PI) / numSlides;

    for (let i = 0; i < numSlides; i++) {
      const slide = slider.slides[i];
      const angle = (i * anglePerSlide) - (2 * Math.PI * progress);
      const x = radius * Math.cos(angle) - slide.offsetWidth / 2;
      const y = radius * Math.sin(angle) - slide.offsetHeight / 2;
      slide.style.transform = `translate3d(${x}px, ${y}px, 0)`;
    }
  };
  *!/

  const swiper = new Swiper('.swiper', { // eslint-disable-line @typescript-eslint/no-unused-vars
    slidesPerView: 'auto', // コンテナ内に表示させるスライド数（CSSでサイズ指定する場合は 'auto'）
    speed: 1200, // スライドアニメーションのスピード（ミリ秒）
    initialSlide: 1, // スライドの開始位置
    centeredSlides : true,
    grabCursor: true, // PCでマウスカーソルを「掴む」マークにする
    watchSlidesProgress: true, // スライドの進行状況を監視する
    mousewheel: true,
    // loop: true,  // 無限ループさせる
    // loopAdditionalSlides: 3, // 無限ループさせる場合に複製するスライド数
    // freeMode: true,

    /!*
    freeMode: {
      enabled: true, // true：自由にドラッグ（スワイプ）できるようにする。スライド位置がスナップしなくなる
      momentum: true, // false：ドラッグ（スワイプ）した後の慣性スクロールをオフにする
      momentumRatio: .1, // 数字が大きいほど惰性スクロールの距離が伸びる（freeMode: trueとfreeModeMomentum:trueが前提）
      momentumVelocityRatio: .2, // 数字が大きいほど惰性スクロールのスピードが速くなる（freeMode: trueとfreeModeMomentum:trueが前提）
      sticky: true,
    },
    *!/

    preventClicks: true, // true: スワイプ中の不要なクリックを無効化
    preventClicksPropagation: true, // true: スワイプ中の不要なクリックイベントを無効化

    on: {
      init: function () {
        updateCustomClasses(this);
      },
      progress: function () {
        // rotateSlider(this, this.progress);
      },
      slideChange: function () {
        updateCustomClasses(this);
      },
    },
  });

}
*/
