/* =============================================================
 * import
 * ========================================================== */
import {gsap} from 'gsap';
import {CustomEase} from "gsap/CustomEase";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {intervalObserver} from "./intervalObserver";
import {mediaQuery} from "./common";

gsap.registerPlugin(CustomEase, ScrollTrigger);
CustomEase.create("fadeCurtain", ".25, .46, .45, .94");


/* =============================================================
 * function
 * ========================================================== */

export function initParallax() {
  scrollScaleImage();
  scrollScaleImageBusiness();
  floatingHeader();
  scrollTxtSwiper();
  scrollTxtClipper();
  loopSvgAnimation();
  imageScrollEffect();

  /*
    window.addEventListener('resize', () => {

      if (!isMobile()) {
        // PCリサイズ時
        scrollScaleImage();
        scrollScaleImageBusiness();
        floatingHeader();
        scrollTxtSwiper();
        scrollTxtClipper();
        loopSvgAnimation();
        imageScrollEffect();
      } else {

      }

      /!*
      const newIsPortrait = window.innerHeight > window.innerWidth;
      if (newIsPortrait !== isPortrait) {
        isPortrait = newIsPortrait;
        ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        setupAnimation();
      }
      *!/
    });
  */

  ScrollTrigger.refresh();
}


function floatingHeader() {

  const targets = gsap.utils.toArray(".dark");
  const header = document.querySelector('#header');
  const hum = document.querySelector('#hum');

  if (targets.length > 0) {

    targets.forEach((target) => {
      ScrollTrigger.create({
        trigger: target,
        start: 'top top+=100',
        end: 'bottom top',
        scrub: true,
        onToggle: self => {
          if (self.isActive) {
            header.classList.add('negative');
            hum.classList.add('negative');
          } else {
            header.classList.remove('negative');
            hum.classList.remove('negative');
          }
        },
      });
    });
  }
}


function scrollScaleImage() {

  const targets = gsap.utils.toArray([".js-scale-wrap"]);

  if (targets.length > 0) {

    targets.forEach((target) => {
      const visual = target.querySelector('.js-scale-visual');
      const img = target.querySelector('.js-scale-img');
      gsap.to(visual, {
        maskSize: "100% 100%", // 1600 -> 1920
        scrollTrigger: {
          trigger: target,
          // pin: true,
          // start: 'center center',
          start: 'bottom bottom',
          end: 'bottom top',
          // start: 'top center', // pin止めしない場合の設定
          // end: 'top top',      // pin止めしない場合の設定
          scrub: true,
          invalidateOnRefresh: true, // update on resize
        },
      });

      let y;
      if (mediaQuery() !== 'sp') {
        y = '6.25vw';
      } else {
        y = 0;
      }

      gsap.to(img, {
        scale: 1.1,
        y: y,
        scrollTrigger: {
          trigger: visual,
          start: 'bottom bottom',
          end: 'bottom top-=100%',
          // start: 'top center', // pin止めしない場合の設定
          // end: 'top top',      // pin止めしない場合の設定
          scrub: true,
          invalidateOnRefresh: true, // update on resize
        },
      });
    });

  }

}


function scrollScaleImageBusiness() {

  const target = document.querySelector('.top_business_head');

  if (target != null) {

    const visual = target.querySelector('.top_business_visual_figure');
    // const ttl = document.querySelector('.top_business .headline');
    const targetTxt = document.querySelector('.top_business_foot');

    gsap.to(visual, {
      scale: 1,
      maskSize: "100% 100%",
      scrollTrigger: {
        trigger: target,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
        invalidateOnRefresh: true, // update on resize
      },
    });

    gsap.to(visual, {
      maskPosition: "50% 100%",
      scrollTrigger: {
        trigger: target,
        start: 'top bottom',
        end: 'bottom center',
        scrub: true,
        invalidateOnRefresh: true, // update on resize
      },
    });

    /*
    gsap.to(ttl, {
      y: "10vh",
      scrollTrigger: {
        trigger: target,
        start: 'top bottom',
        end: 'bottom center',
        scrub: true,
        invalidateOnRefresh: true, // update on resize
      },
    });
    */

    gsap.to(visual, {
      scrollTrigger: {
        trigger: target,
        pin: true,
        pinSpacing: false,
        start: 'center center',
        endTrigger: '#news',
        end: 'bottom top',
        scrub: true,
        invalidateOnRefresh: true, // update on resize
      },
    });

    gsap.to(visual, {
      opacity: .1,
      scrollTrigger: {
        trigger: targetTxt,
        start: 'top bottom',
        end: 'top center',
        scrub: true,
        invalidateOnRefresh: true, // update on resize
      },
    });

    gsap.to(visual, {
      opacity: 0,
      scrollTrigger: {
        trigger: "#news",
        start: 'top bottom',
        end: 'bottom bottom',
        scrub: true,
        invalidateOnRefresh: true, // update on resize
      },
    });

  }

}


function scrollTxtSwiper() {

  const targets = gsap.utils.toArray([".js-scroll-swiper"]);
  if (targets.length > 0) {
    targets.forEach((target) => {
      const txt = target.querySelector('.js-scroll-swiper-txt');
      const offsetLeft = txt.offsetLeft;
      gsap.to(txt, {
        x: () => -(txt.offsetWidth + offsetLeft),  // 横スクロールの長さを設定
        scrollTrigger: {
          pin: true,
          trigger: txt,
          start: 'center center',
          endTrigger: target,
          end: 'bottom top',
          scrub: true,
          invalidateOnRefresh: true, // update on resize
        },
      });
    });
  }

}


function scrollTxtClipper() {

  const targets = gsap.utils.toArray([".js-scroll-clipper"]);

  if (targets.length > 0) {

    targets.forEach((target) => {
      const txt = target.querySelector('.js-scroll-clipper-txt');

      ScrollTrigger.create({
        target: txt,
        pin: true,
        trigger: txt,
        start: 'center center',
        endTrigger: target,
        end: 'bottom top',
        scrub: true,
        invalidateOnRefresh: true, // update on resize
      });

      /*
            gsap.from(txt, {
              clipPath: "inset(100% 0 0 0)",
              scrollTrigger: {
                trigger: txt,
                start: 'center center',
                endTrigger: target,
                end: 'top top',
                scrub: true,
                invalidateOnRefresh: true, // update on resize
              },
            });
      */

      gsap.to(txt, {
        clipPath: "inset(0 0 100% 0)",
        scrollTrigger: {
          trigger: target,
          start: 'bottom bottom',
          end: 'bottom top',
          scrub: true,
          invalidateOnRefresh: true, // update on resize
        },
      });

    });

  }

}


function loopSvgAnimation() {

  const paths01 = document.querySelectorAll('.about_data_history .about_data_list_item_pic_svg .part');
  const pathsCircle01 = document.querySelectorAll('.about_data_history .about_data_list_item_pic_num .circle');
  const pathsCircleBg01 = document.querySelectorAll('.about_data_history .about_data_list_item_pic_num .circle_bg');
  const tl01 = gsap.timeline();
  if (paths01.length > 0) {
    tl01
      .fromTo(
        paths01,
        {
          scaleY: 0,
          transformOrigin: 'bottom',
        },
        {
          scaleY: 1,
          ease: 'bounce.out',
          stagger: {
            duration: .5,
            from: "start",
            each: 0.2,
            // ease: power1.out',
          },
        },
      )
      .fromTo(
        pathsCircle01,
        {
          scale: 0,
          transformOrigin: 'center',
        },
        {
          duration: .6,
          opacity: 0,
          scale: 1.2,
          stagger: {
            duration: .6,
            from: "start",
            each: 0.2,
            // ease: 'power1.out',
          },
        }, ">-=.1",
      )
      .fromTo(
        pathsCircleBg01,
        {
          scale: 0,
          transformOrigin: 'center',
        },
        {
          duration: .6,
          scale: 1,
        }, "<+=.2",
      )
    ;
  }

  const paths02 = document.querySelectorAll('.about_data_bearing .about_data_list_item_pic_svg .sphere');
  const pathsCircle02 = document.querySelectorAll('.about_data_bearing .about_data_list_item_pic_num .circle');
  const pathsCircleBg02 = document.querySelectorAll('.about_data_bearing .about_data_list_item_pic_num .circle_bg');
  const tl02 = gsap.timeline();
  if (paths02.length > 0) {

    tl02
      .fromTo(
        paths02,
        {
          rotate: 0,
          transformOrigin: 'center',
        },
        {
          rotate: 360,
          ease: 'linear',
        },
      )
      .fromTo(
        pathsCircle02,
        {
          scale: 0,
          transformOrigin: 'center',
        },
        {
          duration: .6,
          opacity: 0,
          scale: 1.2,
          stagger: {
            duration: .6,
            from: "start",
            each: 0.2,
            // ease: 'power1.out',
          },
        }, ">-=.1",
      )
      .fromTo(
        pathsCircleBg02,
        {
          scale: 0,
          transformOrigin: 'center',
        },
        {
          duration: .6,
          scale: 1,
        }, "<+=.2",
      )
    ;
  }

  const paths03 = document.querySelectorAll('.about_data_china .about_data_list_item_pic_svg .part');
  const pathsCircle03 = document.querySelectorAll('.about_data_china .about_data_list_item_pic_num .circle');
  const pathsCircleBg03 = document.querySelectorAll('.about_data_china .about_data_list_item_pic_num .circle_bg');
  const tl03 = gsap.timeline();
  if (paths03.length > 0) {
    tl03
      .fromTo(
        paths03,
        {
          scaleY: 0,
          transformOrigin: 'bottom',
        },
        {
          scaleY: 1,
          ease: 'bounce.out',
          stagger: {
            duration: .5,
            from: "start",
            each: 0.1,
            // ease: 'power1.out',
          },
        },
      )
      .fromTo(
        pathsCircle03,
        {
          scale: 0,
          transformOrigin: 'center',
        },
        {
          duration: .6,
          opacity: 0,
          scale: 1.2,
          stagger: {
            duration: .6,
            from: "start",
            each: 0.2,
            // ease: 'power1.out',
          },
        }, ">-=.5",
      )
      .fromTo(
        pathsCircleBg03,
        {
          scale: 0,
          transformOrigin: 'center',
        },
        {
          duration: .6,
          scale: 1,
        }, "<+=.2",
      )
    ;
  }

  const paths04 = document.querySelectorAll('.about_data_world .about_data_list_item_pic_svg .sphere path');
  const pathsNetwork04 = document.querySelectorAll('.about_data_world .about_data_list_item_pic_svg .network');
  const pathsCircle04 = document.querySelectorAll('.about_data_world .about_data_list_item_pic_num .circle');
  const pathsCircleBg04 = document.querySelectorAll('.about_data_world .about_data_list_item_pic_num .circle_bg');
  const tl04 = gsap.timeline();
  if (paths04.length > 0) {
    tl04
      .fromTo(
        paths04,
        {
          scaleY: 0,
          transformOrigin: 'bottom',
        },
        {
          scaleY: 1,
          ease: 'bounce.out',
          stagger: {
            duration: .5,
            from: "start",
            each: 0.05,
            // ease: 'power1.out',
          },
        },
      )
      .fromTo(
        pathsNetwork04,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: .5,
        },
      )
      .fromTo(
        pathsCircle04,
        {
          scale: 0,
          transformOrigin: 'random',
        },
        {
          duration: .6,
          opacity: 0,
          scale: 1.2,
          stagger: {
            duration: .6,
            from: "start",
            each: 0.8,
            // ease: 'power1.out',
          },
        }, ">-=.5",
      )
      .fromTo(
        pathsCircleBg04,
        {
          scale: 0,
          transformOrigin: 'center',
        },
        {
          duration: .6,
          scale: 1,
        }, "<+=.2",
      )
    ;
  }


  /*
  const mainTimeline = gsap.timeline({
    repeat: -1, // 無限に繰り返す
    repeatDelay: 2, // 繰り返し間の待ち時間（秒）
  });
  mainTimeline
    .add(tl01)
    .add(tl02, ">")
    .add(tl03, ">")
    .add(tl04, ">");
  */

  // タイムラインを配列に入れる
  const timelines = [tl01, tl02, tl03, tl04];
  let lastTimeline = null;

  // 配列からランダムなタイムラインを選ぶ関数
  const getRandomTimeline = (timelines) => {
    let randomIndex;
    let randomTimeline;

    do {
      randomIndex = Math.floor(Math.random() * timelines.length);
      randomTimeline = timelines[randomIndex];
    } while (randomTimeline === lastTimeline);

    lastTimeline = randomTimeline;
    return randomTimeline;
  };

  // 一定間隔（5000ミリ秒=5秒）でランダムなタイムラインを実行する
  intervalObserver.observerInterval(() => {
    const randomTimeline = getRandomTimeline(timelines);
    randomTimeline.restart();
  }, 5000);

}


/*
function imageScrollEffect() {
  // Parallax effect 1
  const wrapper = gsap.utils.toArray(".js-pax-outer");

  let viewportDesignWidth;
  if (mediaQuery() !== 'sp') {
    viewportDesignWidth = 1920;
  } else {
    viewportDesignWidth = 390;
  }

  if (wrapper.length > 0) {

    wrapper.forEach(elm => {

      const viewportHeight = window.innerHeight;
      const vwUnit = viewportDesignWidth / 100;

      if (elm.classList.contains('technology_ceramic_bearings')) {

        const scrollTriggerObj = {
          trigger: elm,
          start: 'bottom bottom', // top-=100 bottom
          end: 'bottom top',
          scrub: .1,
          invalidateOnRefresh: true, // update on resize
        };

        if (mediaQuery() !== 'sp') {
          const OuterHeight = 560; // px
          const InnerHeight = 800; // px

          const viewScroll = viewportHeight - OuterHeight;
          const viewMove = viewportHeight - InnerHeight;
          const perScrollMove = viewMove / viewScroll;
          const AdjustScroll = perScrollMove * OuterHeight;

          const DiffHeight = viewMove + AdjustScroll;
          const InnerHeightVw = DiffHeight / vwUnit; // vw

          gsap.fromTo(elm, {
            backgroundPosition: `bottom 0vw left 18.75vw`,
          }, {
            backgroundPosition: `bottom ${InnerHeightVw}vw left 18.75vw`,
            scrollTrigger: scrollTriggerObj,
          });
        } else {
          const InnerHeight = 488; // px
          const viewMove = viewportHeight - InnerHeight;
          const DiffHeight = viewMove;
          const InnerHeightVw = DiffHeight / vwUnit; // vw

          gsap.fromTo(elm, {
            backgroundPosition: `bottom 0vw left 6.1538461538vw`,
          }, {
            backgroundPosition: `bottom ${InnerHeightVw}vw left 6.1538461538vw`,
            scrollTrigger: scrollTriggerObj,
          });
        }

      } else if (elm.classList.contains('technology_stainless_bearings')) {

        const scrollTriggerObj = {
          trigger: elm,
          start: 'bottom bottom', // top-=100 bottom
          end: 'bottom top',
          scrub: .1,
          invalidateOnRefresh: true, // update on resize
        };

        if (mediaQuery() !== 'sp') {
          const OuterHeight = 560; // px
          const InnerHeight = 800; // px

          const viewScroll = viewportHeight - OuterHeight;
          const viewMove = viewportHeight - InnerHeight;
          const perScrollMove = viewMove / viewScroll;
          const AdjustScroll = perScrollMove * OuterHeight;

          const DiffHeight = viewMove + AdjustScroll;
          const InnerHeightVw = DiffHeight / vwUnit; // vw

          gsap.fromTo(elm, {
            backgroundPosition: `bottom 0vw right 18.75vw`,
          }, {
            backgroundPosition: `bottom ${InnerHeightVw}vw right 18.75vw`,
            scrollTrigger: scrollTriggerObj,
          });
        } else {
          const InnerHeight = 488; // px
          const viewMove = viewportHeight - InnerHeight;
          const DiffHeight = viewMove;
          const InnerHeightVw = DiffHeight / vwUnit; // vw

          gsap.fromTo(elm, {
            backgroundPosition: `bottom 0vw right 6.1538461538vw`,
          }, {
            backgroundPosition: `bottom ${InnerHeightVw}vw right 6.1538461538vw`,
            scrollTrigger: scrollTriggerObj,
          });
        }

      } else if (elm.classList.contains('business_feature_next_bg')) {

        if (mediaQuery() !== 'sp') {
          const scrollTriggerObj = {
            trigger: elm,
            start: 'top bottom', // top-=100 bottom
            end: 'bottom top',
            scrub: .1,
            invalidateOnRefresh: true, // update on resize
          };

          gsap.fromTo(elm, {
            backgroundPosition: "top 16.6666666667vw center",
          }, {
            backgroundPosition: "top -16.6666666667vw center",
            scrollTrigger: scrollTriggerObj,
          });
        }

      } else if (elm.classList.contains('business_focus_next_bg')) {

        if (mediaQuery() !== 'sp') {
          const scrollTriggerObj = {
            trigger: elm,
            start: 'top bottom', // top-=100 bottom
            end: 'bottom top',
            scrub: .1,
            invalidateOnRefresh: true, // update on resize
          };

          gsap.fromTo(elm, {
            backgroundPosition: "top 16.6666666667vw center",
          }, {
            backgroundPosition: "top -16.6666666667vw center",
            scrollTrigger: scrollTriggerObj,
          });
        }

      }

    });
  }
}
*/

function imageScrollEffect() {
  // Parallax effect 1
  const pax1 = gsap.utils.toArray(".js-pax-outer");
  if (pax1.length > 0) {
    pax1.forEach(elm => {
      if (window.getComputedStyle(elm).getPropertyValue('display') === 'inline') {
        elm.style.display = 'inline-block';
      }
      const elmOuterHeight = elm.clientHeight;
      const elmInner = elm.querySelector(".js-pax-inner");
      if (elmInner) {
        const elmInnerHeight = elmInner.clientHeight;
        const elmDiffHeight = elmInnerHeight - elmOuterHeight;

        gsap.from(elmInner, {
          y: -elmDiffHeight,
          scrollTrigger: {
            trigger: elm,
            start: 'top bottom', // top-=100 bottom
            end: 'bottom top',
            scrub: 1,
            invalidateOnRefresh: true, // update on resize
          },
        });
      }
    });
  }
}
